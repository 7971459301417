import type { BankAccountState, OrderStatus, TransactionStatus, BankTransactionDirection, BankTransactionPurpose } from '@develit-io/fubex-exchange-prisma'
import type { BankAccountDeep, OrderDeep, OrderBasic, OrdersFilterGroup, PlatformCharge, PlatformChargeDto, TransactionCreateDto, TransactionDeep, UserVerificationDeep, PlatformStatsDto, BankTransaction, PaginatedResponse, AdminObjectsLengths, KrakenBalance, ExchangeOfficeOwnerVerificationDeep } from '@profile/types'
import type { UserDeep } from '@base/types'
import { useToast } from 'vue-toastification'
import { useStorage } from '@vueuse/core'

export const useAdmin = defineStore('admin', () => {
  const toast = useToast()

  const orders = useState<OrderDeep[] | undefined>('admin-orders', () => undefined)
  const isOrdersFetched = useState('admin-orders-isFetched', () => false)
  const ordersBasic = useState<OrderBasic[] | undefined>('admin-orders-basic', () => undefined)
  const isOrdersBasicFetched = useState('admin-orders-basic-isFetched', () => false)
  const ordersBasicCurrentPage = useState<number>('admin-orders-basic-current-page', () => 1)
  const ordersBasicTotal = useState<number>('admin-orders-basic-total', () => 0)

  const transactions = useState<TransactionDeep[] | undefined>('admin-transactions', () => undefined)
  const isTransactionsFetched = useState('admin-transactions-isFetched', () => false)
  const transactionsCurrentPage = useState<number>('admin-transactions-current-page', () => 1)
  const transactionsTotal = useState<number>('admin-transactions-total', () => 0)

  const bankTransactions = useState<BankTransaction[] | undefined>('admin-bankTransactions', () => undefined)
  const isBankTransactionsFetched = useState('admin-bankTransactions-isFetched', () => false)
  const bankTransactionsCurrentPage = useState<number>('admin-bankTransactions-current-page', () => 1)
  const bankTransactionsTotal = useState<number>('admin-bankTransactions-total', () => 0)

  const users = useState<UserDeep[] | undefined>('admin-users', () => undefined)
  const isUsersFetched = useState('admin-users-isFetched', () => false)
  const usersCurrentPage = useState('admin-users-current-page', () => 1)
  const usersTotal = useState('admin-users-total', () => 0)

  const platformCharges = useState<PlatformCharge[] | undefined>('admin-platformCharges', () => undefined)
  const isPlatformChargesFetched = useState('admin-platformCharges-isFetched', () => false)

  const platformStats = useState<PlatformStatsDto | undefined>('admin-platformStats', () => undefined)
  const platformStatsMap = useState<Map<string, PlatformStatsDto>>('admin-platformStats-map', () => new Map())
  const isPlatformStatsFetched = useState('admin-platformStats-isFetched', () => false)
  const isPlatformStatsMapFetched = useState('admin-platformStats-map-isFetched', () => false)

  const krakenBalance = useState<KrakenBalance | undefined>('admin-krakenBalance', () => undefined)
  const isKrakenBalanceFetched = useState('admin-krakenBalance-isFetched', () => false)

  const bankAccounts = useState<BankAccountDeep[] | undefined>('admin-bankAccounts', () => undefined)
  const userVerifications = useState<UserVerificationDeep[] | undefined>()
  const isBankAccountsFetched = useState('admin-bankAccounts-isFetched', () => false)
  const isUserVerificationsFetched = useState(() => false)
  const kybVerifications = useState<UserVerificationDeep[] | undefined>()
  const isKybVerificationsFetched = useState(() => false)
  const exchangeOfficeOwnerVerifications = useState<UserVerificationDeep[] | undefined>()
  const isExchangeOfficeOwnerVerificationsFetched = useState(() => false)
  const { user, isLogged } = storeToRefs(useUser())

  const lastBankAccountsLength = useStorage<number | null>('last-bank-accounts-length', null)
  const lastUserVerificationsLength = useStorage<number | null>('last-user-verifications-length', null)
  const lastKybVerificationsLength = useStorage<number | null>('last-kyb-verifications-length', null)
  const lastExchangeOfficeOwnerVerificationsLength = useStorage<number | null>('last-exchange-office-owner-verifications-length', null)
  const lastTransactionsLength = useStorage<number | null>('last-transcations-length', null)
  const lastOrdersLength = useStorage<number | null>('last-orders-length', null)

  const fetchedBankAccountsLength = useState<number>('fetched-bankAccounts-length', () => 0)
  const fetchedUserVerificationsLength = useState<number>('fetched-userVerifications-length', () => 0)
  const fetchedKybVerificationsLength = useState<number>('fetched-kybVerifications-length', () => 0)
  const fetchedExchangeOfficeOwnerVerificationsLength = useState<number>('fetched-exchange-office-owner-verifications-length', () => 0)
  const fetchedTransactionsLength = useState<number>('fetched-transactions-length', () => 0)
  const fetchedOrdersLength = useState<number>('fetched-orders-length', () => 0)

  const refreshLengths = () => {
    return $fetch('/api/admin/lengths', {
      onResponse ({ response }) {
        const parsed = superjsonParse<AdminObjectsLengths>(response._data) || {}
        fetchedBankAccountsLength.value = parsed.bankAccountsLength ?? 0
        fetchedUserVerificationsLength.value = parsed.overlimitVerificationsLength ?? 0
        fetchedKybVerificationsLength.value = parsed.kybVerificationsLength ?? 0
        fetchedExchangeOfficeOwnerVerificationsLength.value = parsed.exchangeOfficeOwnerVerificationsLength ?? 0
        fetchedOrdersLength.value = parsed.ordersLength ?? 0
        fetchedTransactionsLength.value = parsed.transactionsLength ?? 0
      },
      lazy: true,
    })
  }

  const fetchOrders = async (group?: OrdersFilterGroup) => {
    if (!isLogged.value) {
      orders.value = undefined
      return
    }

    let statuses: OrderStatus[] = []

    if (group === 'open')
      statuses = ['NEW', 'PAID', 'OPEN', 'NEED_VERIFICATION']

    else if (group === 'closed')
      statuses = ['FINALIZED', 'EXPIRED', 'CANCELED', 'SCAM']

    else if (group === 'waiting_to_withdraw')
      statuses = ['WAITING_TO_WITHDRAW']

    let result
    if (statuses.length)
      result = await $fetch(`/api/admin/orders?status=${statuses.join(',')}`, { headers: useRequestHeaders(['cookie']) })

    else
      result = await $fetch('/api/admin/orders', { headers: useRequestHeaders(['cookie']) })

    const parsed = [...superjsonParse<OrderDeep[]>(result)]

    isOrdersFetched.value = true
    if (!result)
      return toast.error('Nepodařilo se načíst objednávky')

    orders.value = parsed
  }

  const fetchOrdersBasic = async (group?: OrdersFilterGroup, query?: string, page: number = 1, fetchAll: boolean = false) => {
    if (!isLogged.value) {
      ordersBasic.value = undefined
      return
    }

    const storedPageSize = localStorage.getItem('admin-orders-page-size') || '15'

    let statuses: OrderStatus[] = []

    if (group === 'open')
      statuses = ['NEW', 'PAID', 'OPEN', 'NEED_VERIFICATION']

    else if (group === 'closed')
      statuses = ['FINALIZED', 'EXPIRED', 'CANCELED', 'SCAM']

    else if (group === 'waiting_to_withdraw')
      statuses = ['WAITING_TO_WITHDRAW']

    const queryParams = new URLSearchParams()
    if (statuses.length) queryParams.append('status', statuses.join(','))
    if (query) queryParams.append('query', encodeURIComponent(query))
    if (!fetchAll) {
      queryParams.append('page', page.toString())
      queryParams.append('pageSize', storedPageSize)
    }

    const result = await $fetch(`/api/admin/orders-basic?${queryParams.toString()}`, { headers: useRequestHeaders(['cookie']) })

    const parsed = superjsonParse<PaginatedResponse<OrderDeep>>(result)

    isOrdersBasicFetched.value = true
    if (!result)
      return toast.error('Nepodařilo se načíst objednávky')

    ordersBasic.value = parsed.results
    if (parsed.wtwStatusTotal)
      fetchedOrdersLength.value = parsed.wtwStatusTotal

    if (!fetchAll) {
      ordersBasicCurrentPage.value = parsed.pageNumber
      ordersBasicTotal.value = parsed.total
    }
  }

  const fetchOrder = async (id: number, force?: boolean) => {
    if (!isLogged.value) {
      orders.value = undefined
      return
    }

    if (orders.value?.find(o => o.orderId === id) && !force)
      return

    const old = orders.value?.find(o => o.orderId === id)

    const result = await $fetch(`/api/admin/orders?id=${id}`, { headers: useRequestHeaders(['cookie']) })

    if (old)
      orders.value?.splice(orders.value!.indexOf(old!), 1)

    const parsed = superjsonParse<OrderDeep[]>(result)

    isOrdersFetched.value = true
    if (!result)
      return toast.error('Error loading order')

    orders.value = [...orders.value ?? [], ...parsed]
  }

  const getOrder = (id: number) => {
    return orders.value?.find(o => o.orderId === id)
  }

  const fetchTransactions = async (status?: string, query?: string, page: number = 1, fetchAll: boolean = false) => {
    if (!isLogged.value) {
      transactions.value = undefined
      return
    }

    const storedPageSize = localStorage.getItem('admin-transactions-page-size') || '15'

    const queryParams = new URLSearchParams()
    if (status) queryParams.append('status', status)
    if (query) queryParams.append('query', encodeURIComponent(query))
    if (!fetchAll) {
      queryParams.append('page', page.toString())
      queryParams.append('pageSize', storedPageSize)
    }

    const result = await $fetch(`/api/admin/transactions?${queryParams.toString()}`, { headers: useRequestHeaders(['cookie']) })

    const parsed = superjsonParse<PaginatedResponse<TransactionDeep>>(result)

    isTransactionsFetched.value = true
    if (!result)
      return toast.error('Nepodařilo se načíst transakce')

    transactions.value = parsed.results
    if (parsed.wtwStatusTotal)
      fetchedTransactionsLength.value = parsed.wtwStatusTotal

    if (!fetchAll) {
      transactionsCurrentPage.value = parsed.pageNumber
      transactionsTotal.value = parsed.total
    }
  }

  const fetchTransaction = async (id: number, force?: boolean) => {
    if (!isLogged.value) {
      orders.value = undefined
      return
    }

    if (transactions.value?.find(t => t.transactionId === id) && !force)
      return

    const old = transactions.value?.find(t => t.transactionId === id)

    const result = await $fetch(`/api/admin/transactions?id=${id}`, { headers: useRequestHeaders(['cookie']) })

    if (old)
      transactions.value?.splice(transactions.value!.indexOf(old!), 1)

    const parsed = superjsonParse<TransactionDeep>(result)

    isTransactionsFetched.value = true
    if (!result)
      return toast.error('Error loading transaction')

    transactions.value = [...transactions.value ?? [], parsed]
  }

  const fetchBankTransactions = async (purpose?: BankTransactionPurpose, direction?: BankTransactionDirection, query?: string, page: number = 1, fetchAll: boolean = false) => {
    if (!isLogged.value) {
      bankTransactions.value = undefined
      return
    }

    const storedPageSize = localStorage.getItem('admin-bank-transactions-page-size') || '15'

    const queryParams = new URLSearchParams()

    if (purpose) queryParams.append('purpose', purpose)
    if (direction) queryParams.append('direction', direction)
    if (query) queryParams.append('query', encodeURIComponent(query))
    if (!fetchAll) {
      queryParams.append('page', page.toString())
      queryParams.append('pageSize', storedPageSize)
    }

    const result = await $fetch(`/api/admin/bank-transactions?${queryParams.toString()}`, { headers: useRequestHeaders(['cookie']) })

    if (!result) {
      return toast.error('Nepodařilo se načíst bankovní transakce')
    }
    const parsed = superjsonParse<PaginatedResponse<BankTransaction>>(result)

    isBankTransactionsFetched.value = true

    bankTransactions.value = parsed.results

    if (!fetchAll) {
      bankTransactionsCurrentPage.value = parsed.pageNumber
      bankTransactionsTotal.value = parsed.total
    }
  }

  const fetchBankTransaction = async (bankTransactionId: number) => {
    if (!isLogged) {
      bankTransactions.value = undefined
      return
    }

    const old = bankTransactions.value?.find(bt => bt.id === bankTransactionId)
    if (old)
      bankTransactions.value?.splice(bankTransactions.value!.indexOf(old!), 1)

    const result = await $fetch(`/api/admin/bank-transactions/${bankTransactionId}`, { headers: useRequestHeaders(['cookie']) })

    if (!result) {
      return toast.error('Nepodařilo se načíst bankovní transakci')
    }
    const parsed = superjsonParse<BankTransaction>(result)

    isBankTransactionsFetched.value = true

    bankTransactions.value = [...bankTransactions.value ?? [], parsed]
  }

  const getBankTransaction = (bankTransactionId: number) => {
    return bankTransactions.value?.find(bt => bt.id === bankTransactionId)
  }

  const fetchBankTransactionsByOrderId = async (orderId: number) => {
    if (!isLogged.value) {
      bankTransactions.value = undefined
      return
    }

    // Delete transactions with the same orderid from bank transactions array.
    bankTransactions.value = (bankTransactions.value || []).filter(transaction => transaction.orderId !== orderId)

    const result = await $fetch(`/api/admin/bank-transactions/orderId/${orderId}`, { headers: useRequestHeaders(['cookie']) })

    const parsed = superjsonParse<BankTransaction[]>(result)

    isBankTransactionsFetched.value = true

    bankTransactions.value = [...bankTransactions.value, ...parsed]
  }

  const getBankTransactionsByOrderId = (orderId: number) => {
    return bankTransactions.value?.filter(bt => {
      if (!bt || !bt.orderId) return false
      return bt.orderId === orderId
    })
  }

  const fetchUsers = async (query?: string, page: number = 1, fetchAll: boolean = false) => {
    if (!isLogged.value) {
      users.value = undefined
      return
    }

    const storedPageSize = localStorage.getItem('users-page-size') || '15'

    const queryParams = new URLSearchParams()

    if (query) queryParams.append('query', encodeURIComponent(query))
    if (!fetchAll) {
      queryParams.append('page', page.toString())
      queryParams.append('pageSize', storedPageSize)
    }

    const result = await $fetch(`/api/admin/users?${queryParams.toString()}`, { headers: useRequestHeaders(['cookie']) })

    if (!result)
      return toast.error('Nepodařilo se načíst uživatele')

    const parsed = superjsonParse<PaginatedResponse<UserDeep>>(result)

    isUsersFetched.value = true

    users.value = parsed.results

    if (!fetchAll) {
      usersCurrentPage.value = parsed.pageNumber
      usersTotal.value = parsed.total
    }
  }

  const fetchUser = async (id: string, force?: boolean) => {
    if (!isLogged.value) {
      users.value = undefined
      return
    }

    if (users.value?.find(u => u.userId === id) && !force)
      return

    const old = users.value?.find(u => u.userId === id)

    const result = await $fetch(`/api/admin/users?id=${id}`, { headers: useRequestHeaders(['cookie']) })

    if (old)
      users.value?.splice(users.value!.indexOf(old!), 1)

    const parsed = superjsonParse<PaginatedResponse<UserDeep>>(result)

    isUsersFetched.value = true

    if (!result)
      return toast.error('Error loading user')

    users.value = [...users.value ?? [], ...parsed.results]
  }

  const updateUserDeleted = async (id: string, data: { deleted: boolean }) => {
    try {
      await $fetch(`/api/admin/${id}/deleted`, {
        method: 'PATCH',
        body: data,
      })
    }
    catch (e: any) {
      return toast.error(e.statusMessage || 'Unable to update user deleted status')
    }
  }

  const deleteAuthUser = async (email: string) => {
    const queryParams = new URLSearchParams()
    queryParams.append('email', encodeURIComponent(email))

    try {
      await $fetch(`/api/admin/auth?${queryParams.toString()}`, {
        method: 'DELETE',
      })
    }
    catch (e: any) {
      return toast.error(e.statusMessage || 'Unable to delete the user invitation')
    }
  }

  const getUser = (id: string) => {
    return users.value?.find(u => u.userId === id)
  }

  const fetchBankAccounts = async () => {
    if (!isLogged.value) {
      bankAccounts.value = undefined
      return
    }
    const result = await $fetch('/api/admin/bank-account/all', { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<BankAccountDeep[]>(result)

    isBankAccountsFetched.value = true
    if (!result)
      return toast.error('Error loading bank accounts')

    bankAccounts.value = parsed.map(b => ({
      ...b,
      fullBankAccountNumber: `${b.bankAccountPrefix ? `${b.bankAccountPrefix}-` : ''}${b.bankAccount}/${b.bankCode}`,
    }))
    fetchedBankAccountsLength.value = bankAccounts.value.length
  }

  const fetchPlatformCharges = async () => {
    if (!isLogged.value) {
      platformCharges.value = undefined
      return
    }
    const result = await $fetch('/api/admin/charge/all', { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<PlatformCharge[]>(result)

    isPlatformChargesFetched.value = true
    if (!result)
      return toast.error('Error loading platform charges')

    platformCharges.value = parsed
  }

  const init = async () => {
    if (user.value?.role !== 'ADMIN')
      return

    return Promise.all([
      fetchOrders(),
      fetchUsers(),
      fetchBankAccounts(),
      fetchPlatformCharges(),
    ])
  }

  const fetchPlatformStats = async (platformId: string) => {
    if (!isLogged.value) {
      platformStats.value = undefined
      platformStatsMap.value = new Map()
      return
    }

    const result = await $fetch(`/api/inspector/stats?platformId=${platformId}`, { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<PlatformStatsDto>(result)

    if (!result)
      return toast.error('Error loading platform stats')

    // If fetching a single plaform by Id, have to save it to the Map.
    // The platformStats variable is reserved for the data of all platforms together
    if (platformId !== 'all') {
      platformStatsMap.value.set(platformId, parsed)
      // This case sets the isFetched flag true when all platforms have been fetched one by one.
      if (platformStats.value && platformStatsMap.value.size === platformStats.value.platforms.length) {
        isPlatformStatsMapFetched.value = true
      }
      // The admin can also just fetch a single platform, not all 6 together
      // In that case, the platformStats value is undefined
      else if (!platformStats.value) {
        isPlatformStatsMapFetched.value = true
      }
    }
    // Fetching data of all platforms together when the query is equal to 'all'
    else {
      platformStats.value = parsed
      isPlatformStatsFetched.value = true
    }
  }

  const fetchKrakenBalance = async () => {
    if (!isLogged.value) {
      krakenBalance.value = undefined
      return
    }

    const result = await $fetch('/api/admin/kraken-balance', { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<KrakenBalance>(result)

    isKrakenBalanceFetched.value = true
    if (!result) {
      return toast.error('Nepodařilo se načíst zůstatek')
    }

    krakenBalance.value = parsed
  }

  const createPlatformCharge = async (data: PlatformChargeDto) => {
    const result = await $fetch('/api/admin/charge/create', {
      method: 'POST',
      body: data,
    })

    if (!result)
      throw createError('Nepodařilo se uložit poplatek')
  }

  const deletePlatformCharge = async (id: number) => {
    const result = await $fetch(`/api/admin/charge/id/${id}`, {
      method: 'DELETE',
    })

    if (!result)
      throw createError('Nepodařilo se smazat poplatek')
  }

  const updatePlatformCharge = async (id: number, data: PlatformChargeDto) => {
    const result = await $fetch(`/api/admin/charge/id/${id}`, {
      method: 'PATCH',
      body: data,
    })

    if (!result)
      throw createError('Nepodařilo se uložit poplatek')
  }

  watch(user, init)
  useAsyncData(init)

  const createTransaction = async (data: TransactionCreateDto) => {
    const result = await $fetch('/api/admin/transaction', {
      method: 'POST',
      body: data,
    })
    const parsed = superjsonParse<TransactionDeep>(result)

    if (!result)
      throw createError('Nepodařilo se vytvořit transakci')

    await fetchOrders()

    return parsed
  }

  const changeBankAccountState = async (id: number, state: BankAccountState) => {
    const result = await $fetch(`/api/admin/bank-account/id/${id}/status`, {
      method: 'PATCH',
      body: { state },
    })

    if (!result)
      throw createError('Nepodařilo se změnit status')
  }

  const changeOrderStatus = async (id: number, status: OrderStatus) => {
    const result = await $fetch(`/api/admin/order/${id}/status`, {
      method: 'PATCH',
      body: { status },
    })

    if (!result)
      throw createError('Nepodařilo se změnit status')
  }

  const changeTransactionStatus = async (id: number, status: TransactionStatus) => {
    const result = await $fetch(`/api/admin/transaction/${id}/status`, {
      method: 'PATCH',
      body: { status },
    })

    if (!result)
      throw createError('Nepodařilo se změnit status')
  }

  const fetchOverlimitVerifications = async () => {
    if (!isLogged.value) {
      userVerifications.value = undefined
      return
    }
    const result = await $fetch('/api/admin/user-verification/overlimit', { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<UserVerificationDeep[]>(result)

    isUserVerificationsFetched.value = true
    if (!result)
      return toast.error('Error loading user verifications')

    userVerifications.value = parsed
    fetchedUserVerificationsLength.value = userVerifications.value.length
    /*  bankAccounts.value = parsed.map(b => ({
      ...b,
      fullBankAccountNumber: `${b.bankAccountPrefix ? `${b.bankAccountPrefix}-` : ''}${b.bankAccount}/${b.bankCode}`,
    })) */
  }

  const fetchKybVerifications = async () => {
    if (!isLogged.value) {
      kybVerifications.value = undefined
      return
    }
    const result = await $fetch('/api/admin/user-verification/kyb', { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<UserVerificationDeep[]>(result)

    isKybVerificationsFetched.value = true
    if (!result)
      return toast.error('Error loading user verifications')

    kybVerifications.value = parsed
    fetchedKybVerificationsLength.value = kybVerifications.value.length
  }

  const fetchExchangeOfficeOwnerVerifications = async () => {
    if (!isLogged.value) {
      exchangeOfficeOwnerVerifications.value = undefined
      return
    }
    const result = await $fetch('/api/admin/user-verification/exchange-office-owner', { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<ExchangeOfficeOwnerVerificationDeep[]>(result)

    isExchangeOfficeOwnerVerificationsFetched.value = true
    if (!result)
      return toast.error('Error loading exchange office owner verifications')

    exchangeOfficeOwnerVerifications.value = parsed
    fetchedExchangeOfficeOwnerVerificationsLength.value = exchangeOfficeOwnerVerifications.value.length
  }

  return {
    orders,
    isOrdersFetched,
    ordersBasic,
    isOrdersBasicFetched,
    ordersBasicCurrentPage,
    ordersBasicTotal,
    fetchedOrdersLength,
    lastOrdersLength,
    bankAccounts,
    lastBankAccountsLength,
    fetchedBankAccountsLength,
    isBankAccountsFetched,
    users,
    isUsersFetched,
    usersCurrentPage,
    usersTotal,
    userVerifications,
    lastUserVerificationsLength,
    fetchedUserVerificationsLength,
    isUserVerificationsFetched,
    kybVerifications,
    lastKybVerificationsLength,
    fetchedKybVerificationsLength,
    isKybVerificationsFetched,
    exchangeOfficeOwnerVerifications,
    lastExchangeOfficeOwnerVerificationsLength,
    fetchedExchangeOfficeOwnerVerificationsLength,
    isExchangeOfficeOwnerVerificationsFetched,
    platformCharges,
    isPlatformChargesFetched,
    platformStats,
    platformStatsMap,
    isPlatformStatsFetched,
    isPlatformStatsMapFetched,
    krakenBalance,
    isKrakenBalanceFetched,
    fetchKrakenBalance,
    fetchPlatformStats,
    fetchOrder,
    fetchOrders,
    fetchOrdersBasic,
    getOrder,
    transactions,
    fetchTransactions,
    fetchTransaction,
    changeTransactionStatus,
    isTransactionsFetched,
    transactionsCurrentPage,
    transactionsTotal,
    lastTransactionsLength,
    fetchedTransactionsLength,
    bankTransactions,
    isBankTransactionsFetched,
    bankTransactionsCurrentPage,
    bankTransactionsTotal,
    fetchBankTransactions,
    fetchBankTransaction,
    getBankTransaction,
    fetchBankTransactionsByOrderId,
    getBankTransactionsByOrderId,
    changeBankAccountState,
    changeOrderStatus,
    fetchBankAccounts,
    createTransaction,
    fetchUsers,
    fetchUser,
    updateUserDeleted,
    deleteAuthUser,
    getUser,
    fetchOverlimitVerifications,
    fetchKybVerifications,
    fetchExchangeOfficeOwnerVerifications,
    fetchPlatformCharges,
    createPlatformCharge,
    deletePlatformCharge,
    updatePlatformCharge,
    refreshLengths,
  }
})
